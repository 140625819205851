<template>
    <div id="error-page">
        <div class="loghi-container">
            <img alt="Compass" id="logo" src="./../assets/logo.svg">
        </div>

        <img alt="Page not found" id="page_not_found" src="./../assets/404.png">
    </div>
</template>

<script>
    export default {
        name: 'ErrorPage',
    }
</script>

<style>
    #error-page {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background-color: #e9ecef;
    }
    #error-page .loghi-container {
        display: flex;
        position: fixed;   
        top: 20px;     
        justify-content: center;
        width: calc(100% - 40px);
    }
    #error-page .loghi-container #logo {
        max-width: 400px;
        margin-top: 30px;
    }
    img#page_not_found {
        width: 60%;
    }
    @media screen and (max-width: 550px) {
        #error-page .loghi-container #logo {
            width: 80%;
            height: unset;
            max-width: 280px;
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 400px) {
        img#page_not_found {
            width: 85%;
        }
    }
</style>