<template>
    <div id="inserted_order">
        <template v-if="insertedOrder.length == 0"> 
            <span class="alert-span title-span">{{ $t("order_monitor.no_order") }}</span>
        </template>

        <template v-else>
            <button id="btn-order" @click="viewInsertedOrderModal()">{{ $t("order_monitor.inserted_orders") }}</button>
            <span class="title-span">{{ $t("order_monitor.take_order") }}{{ $t("order_monitor.when_see") }}</span>

            <div class="contain-called-ticket">
                <template v-for="order in listCalledOrder">
                    <template v-if="insertedOrder.includes(order.num) && order.score==1"> 
                        <div class="called-ticket" v-bind:key="order.num.toString() + order.shop">
                            <span>{{ order.num }}</span>
                            <span class="bottega">{{ order.shop }}</span>
                        </div>
                    </template>
                </template>

                <p class="title-span" v-if="checkForServed()">{{ $t("order_monitor.retired") }}:</p>
                <template v-for="order in listCalledOrder">
                    <template v-if="insertedOrder.includes(order.num) && order.score==2"> 
                        <div class="called-ticket" v-bind:key="order.num.toString() + order.shop">
                            <span>{{ order.num }}</span>
                            <span class="bottega">{{ order.shop }}</span>
                        </div>
                    </template>
                </template>
            </div>
        </template>

        <InsertedOrderModal v-show="isInsertedOrderModalVisible" @close="closeInsertedOrderModal" ref="insertedOrderModal" />
    </div>
</template>

<script>
    import InsertedOrderModal from './InsertedOrderModal.vue'

    export default {
        name: 'InsertedOrder',
        components: {
            InsertedOrderModal
        },
        data() {
            return {
                insertedOrder: [],
                listCalledOrder: [],
                isInsertedOrderModalVisible: false,
            }
        },
        mounted() {
            try{
                let order_data = JSON.parse(localStorage.getItem("orders"));
                let dateString = order_data.timestamp;
                let now = new Date().getTime().toString();

                var hours = Math.abs(now - dateString) / 36e5;
                if(hours < 1){
                    this.insertedOrder = order_data.list;
                    this.$refs.insertedOrderModal.setData(this.insertedOrder);
                }
            }catch(Exception){ console.log("no order list in storage"); }
        },
        methods: {
            setData(arr){  
                this.listCalledOrder = [];
                this.listCalledOrder = arr;
            },
            addOrderToList(order) {
                this.insertedOrder.push(order);
                this.$refs.insertedOrderModal.setData(this.insertedOrder);

                localStorage.removeItem("orders");
                localStorage.setItem("orders", JSON.stringify({list: this.insertedOrder, timestamp: new Date().getTime().toString()}));
            },
            viewInsertedOrderModal() {
                this.isInsertedOrderModalVisible = true;
            },
            closeInsertedOrderModal() {
                this.isInsertedOrderModalVisible = false;          
            },
            forceGetOrderFromLS(){
                try{
                    let order_data = JSON.parse(localStorage.getItem("orders"));
                    let dateString = order_data.timestamp;
                    let now = new Date().getTime().toString();

                    var hours = Math.abs(now - dateString) / 36e5;
                    if(hours < 1){
                        this.insertedOrder = order_data.list;
                        this.$refs.insertedOrderModal.setData(this.insertedOrder);
                    }
                }catch(Exception){ console.log("no order list in storage"); }
            },
            removeFromArray(val) {
                const index = this.insertedOrder.indexOf(val);
                if (index > -1) {
                    this.insertedOrder.splice(index, 1);
                }

                this.$refs.insertedOrderModal.setData(this.insertedOrder);

                localStorage.removeItem("orders");
                localStorage.setItem("orders", JSON.stringify({list: this.insertedOrder, timestamp: new Date().getTime().toString()}));
            },
            checkForServed() {
                for(let i=0; i<this.listCalledOrder.length>0; i++){
                    let order = this.listCalledOrder[i];
                    if(this.insertedOrder.includes(order.num) && order.score==2){
                        return true;
                    }
                }
                return false;
            }
        }
    }
</script>

<style>
    #inserted_order {
        width: 100%;
        display: flex;
        padding-bottom: 50px;
        min-height: calc(100vh - 600px);
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }
    .alert-span {
        margin-top: 50px;
        color: black;
        font-size: 1.2rem;
        font-weight: normal;
        /*text-shadow: 0px 0px 1px rgb(150 150 150);*/
    }
    #btn-order {
        width: 240px;
        padding: 15px 20px;
        color: #060C11;
        margin-top: -20px;
        margin-bottom: 30px;
        text-align: center;
        border-radius: 20px;
        border: 2px solid #bf1217;
        background-color: #E91B21;
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
    }
    button#btn-order:focus {
        outline: none;
    }
    button#btn-order:hover {
        box-shadow: 0 0 0 0.3125rem rgba(233, 27, 33, .4);
        position: relative;
        top: 1px;
    }
    .contain-called-ticket {
        width: 100%;
        margin-top: 40px;
        /*height: -webkit-fill-available;*/
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .contain-called-ticket p.title-span {
        width: 100%;
        color: #343434;
        text-shadow: 2px 2px 2px rgb(150 150 150);
    }
    .called-ticket {
        border: 1px solid black;
        background-color: rgba(0,0,0,.7);
        width: 150px;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        white-space: break-spaces;
        align-items: center;
        flex-wrap: wrap;
    }
    .called-ticket span {
        font-size: 60px;
        color: #E91B21 !important;
    }
    .called-ticket span.bottega {
        font-size: 14px;
        display: none;
    }

    @media screen and (max-width: 650px) {
        .called-ticket span {
            font-size: 1.6rem;
        }
        .title-span {
            font-size: 0.9rem;
        }
        #btn-order {
            width: 200px;
            padding: 15px;
        }
    }

    @media screen and (max-width: 500px) {
        .called-ticket {
            width: 100px;
            height: 100px;
            font-size: 0.8rem;
        }
        #inserted_order {
            min-height: calc(100vh - 440px);
        }
    }
</style>