<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div id="order-inserted-modal" class="modal opacity-class" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header class="modal-header">
                    <slot name="header">
                        <span class="content-header">{{ $t("order_monitor.inserted_orders") }}</span>

                        <!--<button type="button" class="btn-close" @click="close" aria-label="Close modal">x</button>-->
                    </slot>
                </header>
                <section class="modal-body">
                    <slot name="body">
                        <table id="inserted-order-tb">
                            <tr v-for="(order,i) in orderArray" :key="i">
                                <td @click="removeFromArray(order)">
                                    <img class="img-remove" :alt="$t('order_monitor.add_order_2')" src="./../assets/ico_rimuovi.png">
                                    {{ order }}
                                </td>
                            </tr>
                            <transition name="fade">
                                <tr v-if="orderArray.length == 0">
                                    <td>{{ $t("order_monitor.no_inserted") }}</td>
                                </tr>
                            </transition>
                        </table>
                    </slot>
                </section>
                <footer class="modal-footer">
                    <slot name="footer">
                        <button id="btn-close-modal" class="noSelect" @click="close" aria-label="Close modal">{{ $t("order_monitor.close") }}</button>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "InsertedOrderModal",
        data(){
            return {
                orderArray: []
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            setData(arr) {
                this.orderArray = [];
                this.orderArray = arr;
            },
            removeFromArray(val) {
                this.$parent.removeFromArray(val);
            }
        }
    }
</script>

<style>
    .modal-fade-enter, .modal-fade-enter-active,
    .modal-fade-leave-active {
        opacity: 0;
    }
    .modal-fade-enter-to, .modal-fade-leave {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }
    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }
    .modal-header {
        border-bottom: 1px solid #eeeeee;
        justify-content: space-between;
    }
    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }
    .modal-body {
        position: relative;
        padding: 20px 10px;
        overflow: auto;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s ease;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }

    /* - - - */

    div#order-inserted-modal {
        background-color: #e9ecef;
        border-radius: 20px;
        width: 250px;
        height: auto;
    }
    span.content-header {
        text-transform: uppercase;
        font-weight: bold;
        color: black;
        font-size: 0.95rem;
        text-align: center;
        width: 100%;
    }
    /*#order-inserted-modal header.modal-header {
        border-bottom: 1px solid rgba(0,0,0,.1);
    }*/
    #order-inserted-modal footer.modal-footer {
        display: flex;
        justify-content: center;
    }   
    #btn-close-modal {
        width: 200px;
        padding: 15px;
        color: #060C11;
        text-align: center;
        border-radius: 25px;
        border: 2px solid #bf1217;
        background-color: #E91B21;
        -webkit-tap-highlight-color: transparent;
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
    }
    button#btn-close-modal:focus {
        outline: none;
    }
    button#btn-close-modal:hover {
        box-shadow: 0 0 0 0.3125rem rgba(233, 27, 33, .4);
        position: relative;
        top: 1px;
    }
    table#inserted-order-tb {
        width: 60%;
        table-layout: fixed;
        margin-left: 18%;
    }
    table#inserted-order-tb tr td {
        display: flex;
        cursor: pointer;
        color: #3c3c3c;
        padding-top: 20px;
        align-items: center;
        padding-bottom: 20px;
        justify-content: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .img-remove {
        height: 15px;
        margin-top: -2px;
        margin-right: 10px;
        transform: rotate(-45deg);
    }

    @media screen and (max-width: 550px){
        #btn-link-to-site {
            width: 180px;
            font-size: 12px;
        } 
        span.content-header {
            font-size: 0.9rem;
        }
    }

    @media screen and (max-width: 300px){
        div#order-inserted-modal {
            width: 90%;
            margin-left: 5%;
        }
    }
</style>