<template>
    <div id="lang-switcher">
        <span :class="checkForActive(locales[0])" @click="switchLanguage(locales[0])">{{ locales[0] }}</span>/
        <span :class="checkForActive(locales[1])" @click="switchLanguage(locales[1])">{{ locales[1] }}</span>
    </div>
</template>

<script>
    export default {
        name: 'LangSwitcher',
        data() {
            return {
               locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
               default_lang: process.env.VUE_APP_I18N_LOCALE
            }
        },
        beforeMount() {
            this.setEnvLang();
        },
        methods: { 
            setEnvLang() { 
                let lan = localStorage.getItem("landing_lang");
                if(lan != '' && lan != null){ 
                    this.default_lang = lan;
                    this.$i18n.locale = lan;
                }
            },
            switchLanguage(locale) {
                if (this.$i18n.locale !== locale) {
                    this.$i18n.locale = locale;
                    localStorage.setItem("landing_lang", locale);
                }
            },
            checkForActive(locale){
                if (this.$i18n.locale == locale) {
                    return "active";
                }else{
                    return "";
                }
            }
        },
    }
</script>

<style>
    div#lang-switcher {
        width: 100%;
        display: flex;
        cursor: pointer;
        font-size: 13px;
        max-width: 400px;
        margin-bottom: 10px;
        text-transform: uppercase;
        justify-content: flex-end;
        /* color: #c7c7c7; --> sfondo 1 e 2 */
    }
    div#lang-switcher span.active {
        font-weight: bold;
    }
</style>