<template>
    <div id="inserted_new_order">
        <span class="title-span">{{ $t("order_monitor.insert_your") }} {{ $t("order_monitor.order_n") }}</span>
        <input type="text" name="order" class="input-order" autocomplete="off" />

        <table class="confirm-tb" @click="setOrder()">
            <!--<tr>
                <td>
                    <img alt="" class="separe-confirm" src="./../assets/linea_conferma.svg">
                </td>
            </tr>-->
            <tr>
                <td class="confirm-txt">{{ $t("order_monitor.confirm") }}</td>
            </tr>
            <!--<tr>
                <td>
                    <img alt="" class="separe-confirm" src="./../assets/linea_conferma.svg">
                </td>
            </tr>-->
        </table>
    </div>
</template>

<script>
    import $ from 'jquery'

    export default {
        name: "InsertNewOrder",
        methods: {
            setOrder() {
                let order = $("input[name=order]").val();

                if(order == ''){
                    $("input[name=order]").addClass("shake-elm");
                    setTimeout(()=>{
                        $("input[name=order]").removeClass("shake-elm");
                    }, 800);
                }else{
                    this.$parent.setOrder(order);
                }
            }
        }
    }
</script>

<style>
    #inserted_new_order {
        width: 100%;
        padding-bottom: 50px;
        min-height: calc(100vh - 600px);        
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .title-span {
        color: rgba(0,0,0,.66);
        font-size: 16px;
        font-weight: normal;
        text-shadow: 0px 0px 1px rgb(150 150 150);
    }
    span.title-span {
        border: 1px solid grey;
        padding: 20px;
        background: rgba(245,245,245,.6);
        border-radius: 20px;
    }
    .input-order {
        width: 250px;
        padding: 20px;
        color: #3C3C3C;
        margin-top: 30px;
        margin-bottom: 50px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 25px;
        font-weight: bold;
        border: 0px;
        cursor: pointer;
        font-size: 1.3rem;
        z-index: 300 !important;
    }
    .input-order:focus {
        outline: none;
    }
    table.confirm-tb tr td {
        border-radius: 20px;
    }
    .confirm-tb {
        cursor: pointer;
        max-width: 240px;
    }
    .confirm-txt {
        -webkit-tap-highlight-color: transparent;
        border: 2px solid #bf1217;
        background-color: #E91B21;
        text-transform: uppercase;
        border-radius: 5px;
        padding: 15px 20px;
        font-weight: bold;
        font-size: 16px;
        color: #060C11;
        width: 240px;
    }
    .confirm-txt:focus {
        outline: none;
    }
    .confirm-txt:hover {
        box-shadow: 0 0 0 0.3125rem rgba(233, 27, 33, .4);
    }
    .shake-elm {
        /* Start the shake animation and make the animation last for 0.5 seconds */
        animation: shake 0.5s;
        /* When the animation is finished, start again */
        animation-iteration-count: infinite;
    }

    @media screen and (min-width: 801px){
        .confirm-tb:hover {
            position: relative;
            top: 1px;
        }
    }
    @media screen and (min-width: 800px){
        .confirm-tb:active {
            position: relative;
            top: 1px;
        }
    }
    @media screen and (max-width: 650px) {
        #inserted_new_order {
            min-height: calc(100vh - 620px);
            margin-top: -30px;
        }
        .confirm-tb {
            max-width: 200px;
        }
    }
    @media screen and (max-width: 500px) {
        #inserted_new_order {
            min-height: calc(100vh - 500px);
            margin-top: 0px;
        }
        .input-order {
            width: 200px;
            padding: 15px;
            font-size: 0.9rem;
        }
    }

    @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
    }
</style>