<template>
  <div id="app">
      <template v-if="!error_params">
        <div class="wrap-content">
          <div class='contain-lang-switch noSelect'>
            <LangSwitcher />
          </div>

          <div class="loghi-container">
            <img id="logo" src="./assets/logo.svg">
          </div>

          <template v-if="!error">
            <template v-if="showContent">
              <div id="add-order">
                <transition name="fade">
                  <template v-if="show_list">
                    <div class="wrap-action noSelect" @click="openAddOrder()">
                      <img alt="Aggiungi ordine" src="./assets/ico_aggiungi.png">
                      <span class='title-button'>{{ $t("order_monitor.add_order") }}</span>
                    </div>
                  </template>

                  <template v-if="add_order">
                    <div class="wrap-action noSelect" @click="openAddOrder()">
                      <svg viewBox="0 0 24 24" width="30" height="30"><path fill="transparent" d="M0 0h24v24H0z"/><path fill="#E91B21" d="M21 2v20h-2v-7h-4V8a6 6 0 0 1 6-6zm-2 2.53C18.17 5 17 6.17 17 8v5h2V4.53zM9 13.9V22H7v-8.1A5.002 5.002 0 0 1 3 9V3h2v7h2V3h2v7h2V3h2v6a5.002 5.002 0 0 1-4 4.9z"/></svg>
                      <span class="space-left title-button">{{ $t("order_monitor.check_order") }}</span>
                    </div>
                  </template>
                </transition>
              </div>
            </template>

            <transition name="fade">
              <InsertNewOrder v-show="add_order" />
            </transition>
            <transition name="fade">
              <InsertedOrder v-show="show_list" ref="insertedOrder" />
            </transition>
          </template>

          <template v-if="error">
            <div class="contain-error">
              <span v-html="errorContent"></span>
            </div>
          </template>

          <div id="back-home">
            <div id="back_home" class="wrap-action back-home" @click="openAddOrder()">
              <img alt="Aggiungi ordine" src="./assets/backbtn.png">
              <span class="span-back-home">{{ $t("order_monitor.back") }}</span>
            </div>
          </div>

          <p class="copyright-info">
            Copyright Crawling<br>
            Powered By Iconic a Retex App
          </p>
        </div>
      </template>
      <template v-else>
          <ErrorPage />
      </template>
  </div>
</template>

<script>
  import $ from 'jquery'
  import axios from 'axios'
  
  import LangSwitcher from './components/LangSwitcher.vue'
  import InsertNewOrder from './components/InsertNewOrder.vue'
  import InsertedOrder from './components/InsertedOrder.vue'
  import ErrorPage from './components/ErrorPage.vue'

  export default {
    name: 'App',
    components: {
      ErrorPage,
      LangSwitcher,
      InsertedOrder,
      InsertNewOrder,
    },
    data() {
      return {
        error: false,
        last_id_msg: "",
        show_list: true,
        connection: null,
        add_order: false,
        showContent: true,
        ordini_chiamati: [],
        ordini_inseriti: [],
        error_params: false,
        siteroot: process.env.VUE_APP_SITEROOT,
      }
    },
    beforeMount() {
      let url_string = window.location.href;
      let url = new URL(url_string);

      try{
        let city = url.searchParams.get("content"); 
        if(city != '' && city != 'null' && city != null){ 
          localStorage.setItem("city", city);
        }else{
          localStorage.setItem("city", "crawling");  // remove with several shops
        }

        let order = url.searchParams.get("order"); 
        if(order != '' && order != 'null' && order != null){ 
          localStorage.removeItem("orders");
          localStorage.setItem("orders", JSON.stringify({list: [order], timestamp: new Date().getTime().toString()}));
        }/*else{
          this.error_params = true;
          return;
        }*/

      }catch(Exception){
          console.log(Exception);
          this.error_params = true;
      }
    },
    mounted() {
      try{
        let codes = JSON.parse(process.env.VUE_APP_SHOP_INFORMATION); 
        let city = localStorage.getItem("city");
            
        localStorage.setItem("shop", codes[city].id_iconic);
        localStorage.setItem("code", codes[city].shop_code);
        
        this.connectToWebSocket();

      }catch(Exception){ 
        this.error_params = true;
      }
    },
    methods: {
      openAddOrder(){
          if(this.show_list){
              this.show_list = false;
              setTimeout(()=>{
                  this.add_order = true;
                  $("#back_home").fadeIn();
              }, 525);
          }else{
            this.add_order = false;
            setTimeout(()=>{
                $("#back_home").css("display", "none");
                this.show_list = true;
            }, 525);
          }
      },
      setOrder(order) { 
        this.$refs.insertedOrder.addOrderToList(order);
        this.openAddOrder();
        $("input[name=order]").val('');
      },
      connectToWebSocket() {
        let s = localStorage.getItem("shop"); 
        if(s == '' || s == 'null' || s == null){ 
          this.errorContent = "shop non specificato";
          this.showContent = false;
          setTimeout(()=>{
              this.error = true;
          }, 1000);
          return;
        }

        let url = process.env.VUE_APP_WEBSOCKET_URL + process.env.VUE_APP_TENANT + "/order/"+ localStorage.getItem("shop") +"/stream";
        if((this.last_id_msg != '' || this.last_id_msg != 'undefined' || this.last_id_msg != null) && this.last_id_msg.length > 0){
          url = url + "?latest_id="+ this.last_id_msg;
        }     
        this.connection = new WebSocket(url);

        let self = this;
        this.connection.onmessage = function(event) {
          console.log("Read message from websocket");
          this.last_id_msg = JSON.parse(event.data)[0].message_id;
          self.getData();
        }

        this.connection.onclose = function(event) {
          console.error(event); 
          console.log("SOCKET CHIUSA INASPETTATAMENTE")

          setTimeout(()=>{
              self.connectToWebSocket(); 
          }, 3000);
        }
        
        this.connection.onopen = function() {
          console.log("Successfully connected to the websocket...");
        }
      },
      getData() {
        let s = localStorage.getItem("code"); 
        if(s == '' || s == 'null' || s == null){ 
          this.errorContent = "shop code non specificato";
          this.showContent = false;
          setTimeout(()=>{
              this.error = true;
          }, 1000);
          return;
        }

        let arr = [];
        console.log("Fetch data...");
        let url = process.env.VUE_APP_API_URL +"iconic/v1/"+ process.env.VUE_APP_TENANT +"/order/"+ localStorage.getItem("code") +"/stream?score_to=2&score_from=1";
         let headers_ = {headers: {
                          "access-control-allow-origin": "*",
                          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                          'Access-Control-Allow-Methods': '*',
                        }};
        axios.get(url, headers_).then(response => {             
          try{
            for(let i=0; i<response.data.data[1].length; i++){
              let o = response.data.data[1][i];
              
              if(!JSON.stringify(o).includes('order.extra_data') || JSON.stringify(o).includes('"order.extra_data":"0"')){
                arr.push({"id": o['order.qman.id'], "num": o['order.qman.num'], "shop": o['order.qman.rvc_name'], "score": 1});
              }
            }
          }catch(Exception){ console.log("no called orders"); }    

          try{
            for(let i=0; i<response.data.data[2].length; i++){
              let o = response.data.data[2][i]; 

              if(!JSON.stringify(o).includes('order.extra_data') || JSON.stringify(o).includes('"order.extra_data":"0"')){
                arr.push({"id": o['order.qman.id'], "num": o['order.qman.num'], "shop": o['order.qman.rvc_name'], "score": 2});
              }
            }
          }catch(Exception){ console.log("no served orders"); }

          this.$refs.insertedOrder.setData(arr);    
            
        }).catch(err => { 
          if(err == "Error: Request failed with status code 401"){
            this.autenticateApi();
          }else{
            console.error(err);
            this.errorContent = "Si è verificato un errore durante il recupero dello stato dell'ordine.<br>Contattare l'assistenza.<br><br><br>"+ err;
            this.error = true;

            this.showContent = false;
            setTimeout(()=>{
              this.error = true;
            }, 1000);
          }
        });
      },
      autenticateApi() {
        let headers_ = {headers: {
                          "access-control-allow-origin": "*",
                          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                          'Access-Control-Allow-Methods': '*',
                        }};
        let post_data = {"password": process.env.VUE_APP_API_PWD, "tenant": process.env.VUE_APP_TENANT, "username": process.env.VUE_APP_API_USR};
        axios.post(process.env.VUE_APP_API_URL +"auth", post_data, headers_).then(response => {                       
            if(response.access_token != ''){
              console.log("autenticated");
              this.getData();
            }else{
              this.errorContent = "Si è verificato un errore durante la connessione alle API.<br>Contattare l'assistenza."
              this.showContent = false;
              setTimeout(()=>{
                  this.error = true;
              }, 1000);
            }
        }).catch(err => { 
            this.errorContent = "Si è verificato un errore durante la connessione alle API.<br>Contattare l'assistenza."
            this.showContent = false;
            setTimeout(()=>{
                this.error = true;
            }, 1000);
            console.error(err);
        });
      },
    }
  }
</script>

<style>
  html {
    height: 100%;
  }
  html * {
    font-family: 'Sofia Pro', sans-serif !important;
  }
  body {
    margin: 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #343434;
  }
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*height: calc(100vh - 40px);*/
    height: 100%;
    background: url('./assets/background.jpeg');
    background-position: center;
    background-size: cover;
    text-align: center;
    max-width: 800px;
    overflow: auto;
    width: 100%;
  }
  .wrap-content {
    padding: 20px;
    overflow: auto;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    background-color: rgba(255,255,255,.4);
    /* background-color: rgba(255,255,255,.2); ---> sfondo 1 */
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  /* - - - */

  #back_home {
    display: none;
  }
  .title-button {
    text-shadow: 2px 2px 2px rgb(150 150 150);
  }
  .contain-lang-switch {
    display: flex;
    justify-content: center;
    color: #343434 !important;
  }
  .loghi-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .loghi-container #logo {
    margin-top: 20px;
    max-width: 400px;
  }
  #add-order {
    display: flex;
    margin-top: 80px;
    margin-bottom: 80px;
    justify-content: center;
  }
  #add-order img {
    height: 30px;
    margin-right: 20px;
  }
  #add-order span {
    text-transform: uppercase;
    font-weight: bold;
    color: #343434;
    font-size: 1.3rem;
  }
  .wrap-action {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .wrap-action.back-home {
    margin-bottom: 20px;
  }
  #back-home {
    width: 100%;
    display: flex;
    justify-content: center; 
  }
  #back-home img {
    height: 10px;
    margin-right: 10px;
    position: relative;
    top: 1px;    
  }
  #back-home span {
    font-weight: bold;
    color: #8b0000;
    font-size: 14px;
  }
  .span-back-home {
    text-align: left;
  }
  span.space-left {
    margin-left: 20px;
  }
  .copyright-info {
    text-shadow: 1px 1px 2px #000000;
    text-align: center;
    position: relative;
    color: whitesmoke;
    max-width: 800px;
    font-size: 12px;
    width: 100%;
  }
  .contain-error span {
    font-size: 1.3rem;
    color: black;
    font-weight: bold;
    position: relative;
    top: -100px;
  }
  .contain-error {
    height: calc(100vh - 250px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .noSelect:focus {
      outline: none !important;
  }

  @media screen and (min-width: 801px){
    .wrap-action:hover {
      position: relative;
      top: 1px;
    }
  }
  @media screen and (min-width: 800px){
    .wrap-action:active {
      position: relative;
      top: 1px;
    }
  }
  @media screen and (max-width: 550px) {
    #add-order {
      display: flex;
      margin-top: 60px;
      margin-bottom: 40px;
    }
    .contain-error span {
      font-size: 0.95rem;
    }
  }
  @media screen and (max-width: 500px) {
    .loghi-container #logo {
      margin-top: 10px;
      max-width: 280px;
    }
    .title-button {
      font-size: 0.95rem !important;
    }
    #add-order img {
      height: 20px;
    }
    .wrap-action svg {
      height: 24px !important;
    }
  }
  @media screen and (max-width: 400px) {
    .contain-error span {
        font-size: 0.9rem;
    }
  }
</style>
